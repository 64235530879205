import React from "react"
import { Link } from "gatsby"

import Layout from "../../../components/layout"

const imageBaconWrapDuck = '/tysonscore3.azureedge.net/assets/media/wrightbrand/images/recipes/baconwrapduck_large.jpg';
const RecipePage = () => (
    <Layout metaTitle="Bacon Wrapped Duck Recipe with Beer Glaze"
        metaDescription="Change up your next cookout with our bacon wrapped duck recipe. Wrapping anything in bacon makes it better! See the recipe here!"
        metaKeywords="beer can duck, duck recipes with bacon, bacon wrapped duck"
    >
        <meta property="og:image" content={imageBaconWrapDuck} />
        <link rel="image_src" href={imageBaconWrapDuck} />
        <div id="recipe-details-wrapper" >
            <div class="image">
                <img src={imageBaconWrapDuck} class='img-responsive' alt='Bacon Wrapped Duck' />
            </div>

            <div class="inner-wrapper">
                <div id="recipe-details">
                    <div class="container">
                        <div class="content-wrapper">
                            <h1>Bacon Wrapped Duck Recipe with Beer Glaze</h1>

                            <div class="recipe-content">
                                <p >Are burgers and brats getting boring? Change up your next cookout with something a little duckier. Of course, we wouldn&rsquo;t even be talking about this recipe if it didn&rsquo;t also contain rich, delicious bacon, and this has plenty. </p>
                                <p></p>
                                <p> </p>
                                <p>First, our tender, moist duck gets wrapped up in our Naturally Smoked Applewood Bacon. Then, the sweet and savory honey-beer glaze contains crumbled up bits of our Naturally Hickory Smoked Bacon. And that sounds like the opposite of boring to us.</p>
                                <p>
                                    <div><br />
                                    </div>
                                </p>
                            </div>
                        </div>

                        <div class="timing row">
                            <div class="col-md-4 col-xs-12 time">
                                <div class="icon">
                                    <img src="/tysonscore3.azureedge.net/assets/media/prepIcon.png" alt="Prep Time" />
                                </div>
                                <div class="details">
                                    <h4>Prep Time:</h4>
                                    <p>
                                        10&nbsp;minutes                            </p>
                                </div>
                            </div>

                            <div class="col-md-4 col-xs-12 time">
                                <div class="icon">
                                    <img src="/tysonscore3.azureedge.net/assets/media/cookIcon.png" alt="Cook Time" />
                                </div>
                                <div class="details">
                                    <h4>Cook Time:</h4>
                                    <p>
                                        1&nbsp;hour 30&nbsp;minutes                            </p>
                                </div>
                            </div>

                            <div class="col-md-4 col-xs-12 time">
                                <div class="icon">
                                    <img src="/tysonscore3.azureedge.net/assets/media/serveIcon.png" alt="Serving Size" />
                                </div>
                                <div class="details">
                                    <h4>Serving Size:</h4>
                                    <p>4 </p>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>

                <div class="ingredients-wrapper recipe-section">
                    <div class="container">


                        <h2>Ingredients</h2>
                        <div class="row ingredients">
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                6 <span class="uom">Strips</span> of Wright&#174; Brand Applewood Smoked Bacon
                            </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                4 <span class="uom">Strips</span> of Wright&#174; Brand Hickory Smoked Bacon
                            </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                2 <span class="uom">Cans</span> lager style beer
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                1/2 <span class="uom">Cup</span> yellow onion, diced
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                2 <span class="uom">Tablespoons</span> Worcestershire sauce
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                3 <span class="uom">Tablespoons</span> honey
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                3 <span class="uom">Cloves</span> garlic, crushed
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                1/2 <span class="uom">Teaspoon</span> garlic, minced
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                1 <span class="uom">Tablespoon</span> butter
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                1 <span class="uom"></span> whole duck, (4-5 lbs)
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                <span class="uom"></span> garlic pepper seasoning to taste
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                1 <span class="uom"></span> vertical chicken roaster
                </div>
                        </div>


                    </div>
                </div>

                <div class="instructions-wrapper recipe-section">
                    <div class="container">


                        <h2>Directions</h2>
                        <div class="instructions">
                            <p>
                                1. Pre-heat grill over low heat.

                </p>
                            <p>
                                2. Cut the top off of a beer can (empty first, of course). Combine beer, 1 tablespoon Worcestershire sauce, 1 tablespoon honey and 3 cloves of crushed garlic in the can. You can also use any heat-stable cylindrical container.
                </p>
                            <p>
                                3. Generously season the entire duck with garlic pepper seasoning and score a cross-hatch pattern into thighs and breast.

                </p>
                            <p>
                                4. Place duck “standing up” over beer can and rest on vertical chicken roaster. Place aluminum lined sheet pan under the roaster, set into grill and close lid.

                </p>
                            <p>
                                5. Roast duck for 2 hours. Check every now and then for doneness and to drain rendered duck fat from the aluminum pan.
                </p>
                            <p>
                                6. When duck skin has started to brown and crisp, drape 6 pieces of our Applewood bacon (finally — the good stuff) over the breasts and shoulders. Wrap legs with one piece of bacon each.
                </p>
                            <p>
                                7. As duck continues to roast, cook 4 slices of our Hickory smoked bacon until crispy. Allow to cool slightly, crumble in a bowl, and set aside.

                </p>
                            <p>
                                8. Over medium low heat, cook onions in a small skillet until they become translucent. Add 2 tablespoons honey, 2 tablespoons Worcestershire sauce, ½ teaspoon of minced garlic, and beer. Cook until slightly thickened. Remove sauce from heat and whisk in butter and bacon. Keep warm, reserve.
                </p>
                            <p>
                                9. Don’t forget about the duck! When the bacon begins to crisp, it’s ready. Remove duck and let rest for 10 minutes. Carve and top with honey-beer glaze before serving.

                </p>
                        </div>


                        <div></div>
                    </div>
                </div>
            </div>
        </div>


        <article class="locator-callout">
            <div class="quick-form-container">


                <div class="product-locator-search">
                    <h2>Find Our Products</h2>
                    <form data-url="/locator/" action="/locator/" method="get">
                        <input Value="ANY_ANY" id="PROD" name="PROD" type="hidden" value="ANY_ANY" />
                        <div class="input-wrapper">
                            <input data-val="true" data-val-regex="Please enter a 5-digit ZIP code" data-val-regex-pattern="^\d{5}" data-val-required="Please enter a 5-digit ZIP code" id="ZIP" maxLength="5" name="ZIP" placeholder="Enter Zip" type="tel" defaultValue="" />
                            <input type="submit" value="Locate Bacon"></input>
                            <span class="field-validation-valid" data-valmsg-for="ZIP" data-valmsg-replace="true"></span>
                        </div>
                    </form>
                </div>
            </div>
        </article>
    </Layout>
)

export default RecipePage
